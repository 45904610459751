/*.responsive-table {*/
/*    border: 1px solid #ccc;*/
/*    border-collapse: collapse;*/
/*    margin: 0;*/
/*    padding: 0;*/
/*    width: 100%;*/
/*    table-layout: fixed;*/
/*}*/

/*.responsive-table caption {*/
/*    font-size: 1.5em;*/
/*    margin: .5em 0 .75em;*/
/*}*/

/*.responsive-table tr {*/
/*    background-color: #f8f8f8;*/
/*    border: 1px solid #ddd;*/
/*    padding: .35em;*/
/*}*/

/*.responsive-table th,*/
/*.responsive-table td {*/
/*    padding: .625em;*/
/*    text-align: center;*/
/*}*/

/*.responsive-table th {*/
/*    font-size: .85em;*/
/*    letter-spacing: .1em;*/
/*    text-transform: uppercase;*/
/*}*/

@media screen and (max-width: 600px) {
    .responsive-table {
        border: 0;
    }

    .responsive-table caption {
        font-size: 1.3em;
    }

    .responsive-table thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }

    .responsive-table tr {
        border-bottom: 3px solid #ddd;
        display: block;
        margin-bottom: .625em;
    }

    .responsive-table td {
        border-bottom: 1px solid #ddd;
        display: block;
        font-size: .8em;
        text-align: right;
    }

    .responsive-table td::before {
        /*
        * aria-label has no advantage, it won't be read inside a table
        content: attr(aria-label);
        */
        content: attr(data-label);
        float: left;
        font-weight: bold;
        text-transform: uppercase;
    }

    .responsive-table td:last-child {
        border-bottom: 0;
    }
}